export const API_URL = "https://api.secondstage.app"

// export const API_URL = " https://matilde-api-dev.plesh.co"
export const TOOLPRESS_API = "https://api-tool-press-yn26wrnl4q-ey.a.run.app"

export const THEME_CONFIG = {
    mode:"dark",
    gradients: {
        default: ['#008acb', '#008acb'],
        home_question: ['#008acb', '#008acb'],
        applausometro: ['#fc8200', '#fc8200'],
        siNo:['#3a4de4', '#00d8a1'],
        sentiment: ['#3a4de4', '#00d8a1'],
        wordcloud: ['#d71a1e', '#d71a1e'],
        qna: ['#EA4D95', '#E76E51'],
    },
    primary: '#ffed00',
    secondary: '#263238',
    accent: '#EA4D95',
    text: '#eceff1',
    background:"#0f5e9b",
    poll:{
        default:"#fc8200",
        answered:"#008acb",
        correct: '#5caf48',
        wrong: '#f81023'
    }
}