import React, {useEffect, useState} from "react";
import {auth, firebase, firestore} from "../firebase/clientApp";
import VoteContent from "../components/VoteContent";
import {useLocation, useParams} from "react-router-dom";
import {Box, Container} from "@mui/material";
import {useDocument} from "react-firebase-hooks/firestore";
import axios from "axios";
import {API_URL} from "../config";

export default function Vote() {

    const {id} = useParams()

    // TODO: aggiungere pescaggio di query Params per il codice a 4 cifre dell utente
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get('code');
    // console.info(code);

    const [user, setUser] = useState(null);
    const [event, eventLoading, eventError] = useDocument(firestore.doc('users/plesh/events/' + id));
    const [displayName, setDisplayName] = useState('');
    const [userInfo, setUserInfo] = useState({ displayName: '', teamName: '' });

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getInfo(code);
    }, [code]); // Run once on component mount

    async function loginUser() {
        await auth.signInAnonymously().then(() => {}).catch(alert);
    }

    // TODO: funzione che ricavi, dal codice a 4 cifre, il nome dell'utente
    async function getInfo() {
        // TODO: call the API for 4 num code
        try {
            const res = await axios.get(`https://custom.2ndstage.app/user/${code}`);
            const { nome, cognome, squadra } = res.data;
            // console.info(nome + cognome + squadra);
            setUserInfo({ displayName: `${nome} ${cognome}`, teamName: squadra });
        } catch (error) {
            console.error('Failed to fetch user info:', error);
        }
    }

    useEffect(async () => {
        if(!!userInfo.displayName) {
            setLoading(true)
            await loginUser()
            firebase.auth().onAuthStateChanged(async (user) => {
                setUser(user);
                // TODO: Aggiungere displayName alla post -------------------qui {.., displayName: name }
                await axios.post(`${API_URL}/events/${id}/users`,{user: user.uid, displayName: userInfo.displayName, teamName: userInfo.teamName, customField: code})
                    .then(res => {
                            // Do something?
                            setDisplayName(res.data.displayName ?? '')
                        }
                    )
                    .catch()
            })
            setLoading(false)
        }
    }, [userInfo])

    if (loading) return <></>

    return (
        <Box>
            {(event && !eventLoading && !eventError) &&
                <Container maxWidth={'sm'} className={'vote'} style={{maxHeight: '100%'}}>
                    <VoteContent user={user} event={event} loading={loading} displayName={displayName} setDisplayName={setDisplayName}/>
                </Container>
            }
        </Box>
    )
}
